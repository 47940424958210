/**
 * Get the locale currently being used by the customer.
 *
 * This is made available by an inline script included in the frame when the
 * page is rendered.
 *
 * @returns {string}
 */
export const getLocale = () => window.mv?.session?.locale ?? 'en';

/**
 * Get the URL path prefix that should be used for the current locale.
 *
 * @returns {string}
 */
export const getLocalePathPrefix = () => window.localePathPrefix ?? '';

/**
 * Get the customer's delivery country.
 *
 * @returns {string}
 */
export const getDeliveryCountry = () => window.mv?.session?.deliveryCountry ?? 'GB';
